import { useAppSelector } from "../../../hooks";
import { Card, CardContent, CardHeader, Container, Divider, Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatTime } from "../../../utils";

export default function TableView() {
  const matches = useAppSelector((state) => state.matches.groupMatches);

  return (
    <Container maxWidth="xl">
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="h4">Tabel</Typography>
        </Stack>
        <Card>
          <CardHeader subheader="" title="Tabelvisning af turneringsplan" />
          <Divider />
          <CardContent>
            <Stack spacing={3}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Nr.</TableCell>
                      <TableCell align="right">Start</TableCell>
                      <TableCell align="right">Hometeam</TableCell>
                      <TableCell align="right">Awayteam</TableCell>
                      <TableCell align="right">Pulje</TableCell>
                      <TableCell align="right">Bane</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {matches.map((match, index) => (
                      <TableRow key={match.id}>
                        <TableCell align="right">{index + 1}</TableCell>
                        <TableCell align="right">{formatTime(match.startTime)}</TableCell>
                        <TableCell align="right">{match.homeTeam?.teamName ?? match.homeTeamLabel ?? "-"}</TableCell>
                        <TableCell align="right">{match.awayTeam?.teamName ?? match.awayTeamLabel ?? "-"}</TableCell>
                        <TableCell align="right">{match.pulje.puljeName}</TableCell>
                        <TableCell align="right">{match.location.location}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </CardContent>
          <Divider />
        </Card>
      </Stack>
    </Container>
  );
}
